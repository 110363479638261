import { ThemeContextConsumer } from "../../../../../application/themeContext";
import { makeStyles } from "@mui/styles";
import { query } from "./graph";
import { useQuery } from "@apollo/client";
import { useRouter } from "found";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AppBar from "@mui/material/AppBar";
import BrightnessMediumIcon from "@mui/icons-material/BrightnessMedium";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React, { useState } from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import config from "../../../../config";

const useStyles = makeStyles(theme => ({
  colorPrimary: {
    backgroundColor: "#7fb383"
  },
  currentViewer: {
    marginLeft: theme.spacing(1)
  },
  menuTextSpacing: {
    marginLeft: theme.spacing(1.5)
  }
}));

const AppLauncherAppBar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);

  const classes = useStyles();
  const { router } = useRouter();
  const { data } = useQuery(query);

  const handleSignOutButtonClick = () => {
    window.location =
      config.getFlynetCoreLogoutUrl() +
      "?client_id=" +
      config.getFlynetCoreClientId() +
      "&return_to=" +
      window.location.origin;
  };

  return (
    <AppBar className={classes.colorPrimary} position="static">
      <Toolbar>
        <Typography
          variant="h6"
          color="inherit"
          onClick={() => {
            router.push("/mach6/apps");
          }}
          style={{ flexGrow: 1 }}
        >
          Mach6 Flynet
        </Typography>

        <IconButton onClick={event => setAnchorEl(event.currentTarget)}>
          <AccountCircleIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={isOpen}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem>
            <AccountCircleIcon />
            <Typography className={classes.currentViewer}>
              {data && data.viewer && data.viewer.me.name}
            </Typography>
          </MenuItem>
          <MenuItem>
            <ThemeContextConsumer>
              {context =>
                context &&
                context.componentMounted && (
                  <React.Fragment>
                    <IconButton onClick={context.toggleTheme}>
                      <BrightnessMediumIcon color="action" />
                      {context.type === "light" && (
                        <Typography className={classes.menuTextSpacing}>
                          Toggle Dark Mode
                        </Typography>
                      )}
                      {context.type === "dark" && (
                        <Typography className={classes.menuTextSpacing}>
                          Toggle Light Mode
                        </Typography>
                      )}
                    </IconButton>
                  </React.Fragment>
                )
              }
            </ThemeContextConsumer>
          </MenuItem>
          <MenuItem>
            <Button fullWidth onClick={handleSignOutButtonClick}>
              <Typography align="center">Sign out</Typography>
            </Button>
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default AppLauncherAppBar;
