import { fragments as dtgOrderStatusFragments } from "../DTGOrderStatus/graph";
import { filter } from "graphql-anywhere";
import { query } from "./graph";
import { useQuery } from "@apollo/client";
import { useRouter } from "found";
import AppBar from "../../../../../components/AppBar";
import CircularProgress from "@mui/material/CircularProgress";
import DTGOrderStatus from "../DTGOrderStatus";
import OrderDetailWithStatusHeader from "../../../../../components/OrderDetailWithStatusHeader";
import React from "react";

const DTGOrderDetail = ({ orderId }) => {
  const { router } = useRouter();

  const { data, loading, error } = useQuery(query, {
    skip: !orderId,
    variables: { orderId }
  });

  const handleAppBarRequestBack = () => {
    router.push("/apps/production/dtg");
  };

  const handleOrderDetailRequestClose = () => {
    router.push("/apps/production/dtg");
  };

  return (
    <OrderDetailWithStatusHeader
      destinationRoute="/apps/production/dtg/orders"
      errorMessage={error ? error.message : null}
      loading={loading}
      onRequestClose={handleOrderDetailRequestClose}
      order={data?.order}
      orderStatusView={
        data?.order ? (
          <DTGOrderStatus
            order={filter(dtgOrderStatusFragments.order, data.order)}
          />
        ) : (
          <CircularProgress />
        )
      }
      shouldShowCustomerArtworkWithNotes={false}
      shouldShowInkColors={false}
      title="DTG"
      viewer={data?.viewer}
    >
      <AppBar title="DTG" onRequestBack={handleAppBarRequestBack} />
    </OrderDetailWithStatusHeader>
  );
};

export default DTGOrderDetail;
