import { query } from "./graph";
import { useQuery } from "@apollo/client";
import { useRouter } from "found";
import AppBar from "../../../components/AppBar";
import ProductionAppWithSidebar from "../../../components/ProductionAppWithSidebar";
import React, { useCallback, useState } from "react";

const ORDERS_PER_PAGE = 25;

const ArtApp = () => {
  const [searchQuery, setSearchQuery] = useState(null);
  const [status] = useState("ARTBOT_DESIGN_REVIEW");
  const { router } = useRouter();

  const { data, loading, error, fetchMore } = useQuery(query, {
    variables: {
      first: ORDERS_PER_PAGE,
      filters: {
        searchQuery: searchQuery,
        status: status
      }
    },
    fetchPolicy: "network-only"
  });

  const handleSearchQuerySubmit = useCallback(
    query => {
      setSearchQuery(query);
    },
    [setSearchQuery]
  );

  const handleAppBarRequestBack = useCallback(() => {
    router.push("/apps");
  }, [router]);

  return (
    <ProductionAppWithSidebar
      data={data}
      destinationRoute="/apps/production/art/orders"
      errorMessage={error ? error.message : null}
      fetchMore={fetchMore}
      loading={loading}
      ordersConnection={data?.ordersConnection}
    >
      <AppBar
        title="Art"
        onSearch={handleSearchQuerySubmit}
        onRequestBack={handleAppBarRequestBack}
      />
    </ProductionAppWithSidebar>
  );
};

export default ArtApp;
