import { fragments as dtgOrderStatusFragments } from "../../application/apps/DTGApp/components/DTGOrderStatus/graph";
import { gql } from "@apollo/client";

const markOrderAsDtgCompletedMutation = gql`
  mutation($orderId: ID!) {
    markOrderAsDtgCompleted(orderId: $orderId) {
      errors {
        orderId
      }
      order {
        ...DTGOrderStatus_order
      }
      succeeded
    }
  }
  ${dtgOrderStatusFragments.order}
`;

export default markOrderAsDtgCompletedMutation;
