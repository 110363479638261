import { fragments as dtgOrderStatusFragments } from "../../application/apps/DTGApp/components/DTGOrderStatus/graph";
import { gql } from "@apollo/client";

const markOrderAsDtgStartedMutation = gql`
  mutation($orderId: ID!) {
    markOrderAsDtgStarted(orderId: $orderId) {
      errors {
        orderId
      }
      order {
        ...DTGOrderStatus_order
      }
      succeeded
    }
  }
  ${dtgOrderStatusFragments.order}
`;

export default markOrderAsDtgStartedMutation;
