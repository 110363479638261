// @flow

import * as React from "react";
import {
  compose,
  setDisplayName,
  withHandlers,
  withStateHandlers
} from "recompose";

import { markOrderAsHighDefinitionDigitalLocated } from "../../../graph";
import { playAudioForSuccess } from "../../../helpers/playAudioForSuccess";
import { recordOrderProductionJobScreenLocation } from "../../../graph";
import { withRouter } from "found";
import BarcodeScanner from "react-barcode-reader/src";
import PendingStatusView from "../../../components/PendingStatusView";
import TabbedAppBar from "../../../components/TabbedAppBar";
import Typography from "@mui/material/Typography";
import withSnackbar from "../../../components/withSnackbar";
import type { HOC } from "recompose";

type State = {|
  currentLocation: string,
  isLoading: boolean
|};

const enhancer: HOC<*, *> = compose(
  setDisplayName("BulkFilmLocationSetter"),

  withRouter,

  withSnackbar,

  recordOrderProductionJobScreenLocation,

  markOrderAsHighDefinitionDigitalLocated,

  withStateHandlers(
    ({
      isLoading: false,
      currentLocation: null
    }: State),
    {
      setIsLoading: () => (isLoading: boolean) => ({ isLoading }),
      setCurrentLocation: () => (currentLocation: string) => ({
        currentLocation
      })
    }
  ),

  withHandlers({
    handleAppHeaderRequestBack:
      ({ router, backUrl }) =>
      () => {
        router.push(backUrl || "/apps");
      },

    handleBarcodeError:
      ({ showErrorSnackbar, setIsLoading }) =>
      () => {
        showErrorSnackbar("Error scanning barcode");
        setIsLoading(false);
      },

    handleBarcodeScan:
      ({
        currentLocation,
        markOrderAsHighDefinitionDigitalLocated,
        recordOrderProductionJobScreenLocation,
        setCurrentLocation,
        setIsLoading,
        showErrorSnackbar,
        showSuccessSnackbar
      }) =>
      barcode => {
        try {
          const data = JSON.parse(barcode);
          const { variant, type, id, name } = data;
          if (
            variant === "LOCATION" &&
            type.toLowerCase() === "screen" &&
            name
          ) {
            setCurrentLocation(name);
          } else if (
            variant === "OBJECT" &&
            type === "OrderProductionJob" &&
            id
          ) {
            recordOrderProductionJobScreenLocation({
              variables: { location: currentLocation, orderProductionJobId: id }
            })
              .then(
                ({
                  data: {
                    recordOrderProductionJobScreenLocation: {
                      errors,
                      succeeded,
                      updatedOrderProductionJob
                    }
                  }
                }) => {
                  if (succeeded) {
                    const orderId = updatedOrderProductionJob.order.id;
                    const highDefinitionDigitalStatus =
                      updatedOrderProductionJob.order
                        .highDefinitionDigitalStatus;

                    if (highDefinitionDigitalStatus !== "LOCATED") {
                      markOrderAsHighDefinitionDigitalLocated({
                        variables: { orderId }
                      })
                        .then(
                          ({
                            data: {
                              markOrderAsHighDefinitionDigitalLocated: {
                                succeeded,
                                errors
                              }
                            }
                          }) => {
                            if (succeeded) {
                              showSuccessSnackbar(
                                "Order production job box location has been recorded."
                              );
                              playAudioForSuccess();
                            } else {
                              showErrorSnackbar(errors.orderId.join(", "));
                            }
                          }
                        )
                        .catch(e => showErrorSnackbar(e.message));
                    } else {
                      showSuccessSnackbar(
                        "Order production job box location has been recorded."
                      );
                    }
                  } else {
                    showErrorSnackbar(errors.orderProductionJobId.join(", "));
                  }
                }
              )
              .catch(e => {
                showErrorSnackbar(e.message);
              });
          }
        } catch {
          setIsLoading(false);
          showErrorSnackbar("Error Scanning Barcode");
        }
      }
  })
);

const BulkFilmLocationSetter = ({
  appBarBackgroundColor,
  currentLocation,
  handleAppHeaderRequestBack,
  handleBarcodeError,
  handleBarcodeScan,
  isLoading
}) => {
  return (
    <div>
      <TabbedAppBar
        title="Bulk Film Location Setter"
        onRequestBack={handleAppHeaderRequestBack}
        appBarBackgroundColor={appBarBackgroundColor}
      />
      {isLoading ? (
        <PendingStatusView status="Loading" />
      ) : (
        <div>
          <BarcodeScanner
            onError={handleBarcodeError}
            onScan={handleBarcodeScan}
          />
          {currentLocation ? (
            <Typography
              variant="h6"
              align="center"
              color="inherit"
              style={{ flexGrow: 1, marginTop: "10em" }}
            >
              Current Location: {currentLocation}
              <br />
              Scan Film or new location
            </Typography>
          ) : (
            <Typography
              variant="h6"
              align="center"
              color="inherit"
              style={{ flexGrow: 1, marginTop: "10em" }}
            >
              Scan Location
            </Typography>
          )}
        </div>
      )}
    </div>
  );
};

export default enhancer(BulkFilmLocationSetter);
