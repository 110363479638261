import { fragments as artOrderStatusFragments } from "../ArtOrderStatus/graph";
import { filter } from "graphql-anywhere";
import { query } from "./graph";
import { useQuery } from "@apollo/client";
import { useRouter } from "found";
import AppBar from "../../../../../components/AppBar";
import ArtOrderStatus from "../ArtOrderStatus";
import CircularProgress from "@mui/material/CircularProgress";
import OrderDetailWithStatusHeader from "../../../../../components/OrderDetailWithStatusHeader";
import React, { useCallback } from "react";

const ArtOrderDetail = ({ orderId }) => {
  const { router } = useRouter();

  const { data, loading, error } = useQuery(query, {
    skip: !orderId,
    variables: { orderId }
  });

  const handleAppBarRequestBack = useCallback(() => {
    router.push("/apps/production/art");
  }, [router]);

  const handleOrderDetailRequestClose = useCallback(() => {
    router.push("/apps/production/art");
  }, [router]);

  return (
    <OrderDetailWithStatusHeader
      errorMessage={error ? error.message : null}
      loading={loading}
      onRequestClose={handleOrderDetailRequestClose}
      order={data?.order}
      orderStatusView={
        data && data.order ? (
          <ArtOrderStatus
            order={filter(artOrderStatusFragments.order, data.order)}
          />
        ) : (
          <CircularProgress />
        )
      }
      isArtApp={true}
      shouldShowSalesNotes={true}
      shouldShowCustomerArtworkWithNotes={true}
      shouldShowInkColors={true}
      title="Art"
      viewer={data?.viewer}
    >
      <AppBar title="Art" onRequestBack={handleAppBarRequestBack} />
    </OrderDetailWithStatusHeader>
  );
};

export default ArtOrderDetail;
