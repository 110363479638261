import { gql } from "@apollo/client";
import { fragments as vinylOrderStatusFragments } from "../../application/apps/VinylApp/components/VinylOrderStatus/graph";

const markOrderAsVinylCompletedMutation = gql`
  mutation($orderId: ID!) {
    markOrderAsVinylCompleted(orderId: $orderId) {
      errors {
        orderId
      }
      order {
        ...VinylOrderStatus_order
      }
      succeeded
    }
  }
  ${vinylOrderStatusFragments.order}
`;

export default markOrderAsVinylCompletedMutation;
