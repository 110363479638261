import { fragments as embroideryOrderStatusFragments } from "../EmbroideryOrderStatus/graph";
import { filter } from "graphql-anywhere";
import { query } from "./graph";
import { useQuery } from "@apollo/client";
import { useRouter } from "found";
import AppBar from "../../../../../components/AppBar";
import CircularProgress from "@mui/material/CircularProgress";
import EmbroideryOrderStatus from "../EmbroideryOrderStatus";
import OrderDetailWithStatusHeader from "../../../../../components/OrderDetailWithStatusHeader";
import React from "react";

const EmbroideryOrderDetail = ({ orderId }) => {
  const { router } = useRouter();

  const { data, loading, error } = useQuery(query, {
    skip: !orderId,
    variables: { orderId }
  });

  const handleAppBarRequestBack = () => {
    router.push("/apps/production/embroidery");
  };

  const handleOrderDetailRequestClose = () => {
    router.push("/apps/production/embroidery");
  };

  return (
    <OrderDetailWithStatusHeader
      errorMessage={error ? error.message : null}
      loading={loading}
      onRequestClose={handleOrderDetailRequestClose}
      order={data?.order}
      orderStatusView={
        data && data.order ? (
          <EmbroideryOrderStatus
            order={filter(embroideryOrderStatusFragments.order, data.order)}
          />
        ) : (
          <CircularProgress />
        )
      }
      shouldShowCustomerArtworkWithNotes={true}
      shouldShowInkColors={false}
      title="Embroidery"
      viewer={data?.viewer}
    >
      <AppBar title="Embroidery" onRequestBack={handleAppBarRequestBack} />
    </OrderDetailWithStatusHeader>
  );
};

export default EmbroideryOrderDetail;
