import PersonImageAvatar from "./PersonImageAvatar";
import PersonInitialsAvatar from "./PersonInitialsAvatar";
import React from "react";

const PersonAvatar = ({ photographUrl, name, size }) => {
  if (photographUrl) {
    return (
      <PersonImageAvatar
        photographUrl={photographUrl}
        name={name}
        size={size}
      />
    );
  }

  return <PersonInitialsAvatar name={name} size={size} />;
};

export default PersonAvatar;
