import { filter } from "graphql-anywhere";
import { query } from "./graph";
import { useQuery } from "@apollo/client";
import { useRouter } from "found";
import { fragments as vinylOrderStatusFragments } from "../VinylOrderStatus/graph";
import AppBar from "../../../../../components/AppBar";
import CircularProgress from "@mui/material/CircularProgress";
import OrderDetailWithStatusHeader from "../../../../../components/OrderDetailWithStatusHeader";
import React, { useCallback } from "react";
import VinylOrderStatus from "../VinylOrderStatus";

const VinylOrderDetail = ({ orderId }) => {
  const { router } = useRouter();

  const { data, loading, error } = useQuery(query, {
    variables: { orderId },
    skip: !orderId
  });

  const handleAppBarRequestBack = useCallback(() => {
    router.push("/apps/production/vinyl");
  }, [router]);

  return (
    <OrderDetailWithStatusHeader
      errorMessage={error ? error.message : null}
      loading={loading}
      onRequestClose={handleAppBarRequestBack}
      order={data?.order}
      orderStatusView={
        data?.order ? (
          <VinylOrderStatus
            order={filter(vinylOrderStatusFragments.order, data.order)}
          />
        ) : (
          <CircularProgress />
        )
      }
      shouldShowCustomerArtworkWithNotes={false}
      shouldShowInkColors={false}
      title="Vinyl"
      viewer={data?.viewer}
    >
      <AppBar title="Vinyl" onRequestBack={handleAppBarRequestBack} />
    </OrderDetailWithStatusHeader>
  );
};

export default VinylOrderDetail;
