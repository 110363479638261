import * as React from "react";
import { getLabelForOrderMethodStatus } from "../../../../../helpers";
import { makeStyles } from "@mui/styles";
import {
  markOrderAsNoLongerOnHoldMutation,
  markOrderAsVinylCompletedMutation,
  markOrderAsVinylCutMutation
} from "../../../../../graph";
import { useMutation } from "@apollo/client";
import { useState } from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import JobStatusIcon from "../../../../../components/JobStatusIcon";
import PlaceOrderOnHoldModal from "../../../../../components/PlaceOrderOnHoldModal";
import Typography from "@mui/material/Typography";
import withSnackbar from "../../../../../components/withSnackbar";

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    display: "flex",
    justifyContent: "space-around",
    marginTop: theme.spacing(1)
  },

  jobStatus: {
    marginLeft: theme.spacing(1)
  },

  spaceBetweenContainer: {
    display: "flex"
  },

  spacerRight: {
    marginRight: theme.spacing(1)
  },

  spinnerWrapper: {
    padding: theme.spacing(1),
    position: "relative",
    textAlign: "center"
  }
}));

const VinylOrderStatus = ({
  order: {
    canViewerCompleteVinyl,
    canViewerCutVinyl,
    canViewerPlaceOnHold,
    canViewerRemoveHold,
    id: orderId,
    vinylStatus
  },
  showSuccessSnackbar,
  showErrorSnackbar
}) => {
  const classes = useStyles();

  // States:
  const [isMutatingOrder, setIsMutatingOrder] = useState(false);
  const [isPlacingOrderOnHold, setIsPlacingOrderOnHold] = useState(false);

  // Mutations:
  const [markOrderAsNoLongerOnHold] = useMutation(
    markOrderAsNoLongerOnHoldMutation
  );
  const [markOrderAsVinylCompleted] = useMutation(
    markOrderAsVinylCompletedMutation
  );
  const [markOrderAsVinylCut] = useMutation(markOrderAsVinylCutMutation);

  // Handlers:
  const handleHoldButtonClick = () => setIsPlacingOrderOnHold(true);

  const handlePlaceOrderOnHoldModalRequestClose = () =>
    setIsPlacingOrderOnHold(false);

  const handleRemoveHoldButtonClick = () => {
    setIsMutatingOrder(true);
    markOrderAsNoLongerOnHold({ variables: { orderId } })
      .then(
        ({
          data: {
            markOrderAsNoLongerOnHold: { succeeded, errors }
          }
        }) => {
          if (succeeded) {
            showSuccessSnackbar(`The order has been updated`);
          } else {
            showErrorSnackbar(
              `Something went wrong: ${errors.orderId.join(", ")}`
            );
          }
        }
      )
      .catch(e => {
        showErrorSnackbar(`Something went wrong: ${e.message}`);
      })
      .finally(() => setIsMutatingOrder(false));
  };

  const handleVinylCompletedButtonClick = () => {
    setIsMutatingOrder(true);
    markOrderAsVinylCompleted({ variables: { orderId } })
      .then(
        ({
          data: {
            markOrderAsVinylCompleted: { succeeded, errors }
          }
        }) => {
          if (succeeded) {
            showSuccessSnackbar(`The order has been updated`);
          } else {
            showErrorSnackbar(
              `Something went wrong: ${errors.orderId.join(", ")}`
            );
          }
        }
      )
      .catch(e => {
        showErrorSnackbar(`Something went wrong: ${e.message}`);
      })
      .finally(() => setIsMutatingOrder(false));
  };

  const handleCutButtonClick = () => {
    setIsMutatingOrder(true);
    markOrderAsVinylCut({ variables: { orderId } })
      .then(
        ({
          data: {
            markOrderAsVinylCut: { succeeded, errors }
          }
        }) => {
          if (succeeded) {
            showSuccessSnackbar(`The order has been updated`);
          } else {
            showErrorSnackbar(
              `Something went wrong: ${errors.orderId.join(", ")}`
            );
          }
        }
      )
      .catch(e => {
        showErrorSnackbar(`Something went wrong: ${e.message}`);
      })
      .finally(() => setIsMutatingOrder(false));
  };

  return (
    <div>
      <div className={classes.spaceBetweenContainer}>
        <JobStatusIcon status={vinylStatus} />
        <div className={classes.jobStatus}>
          <Typography variant="h6">Vinyl</Typography>
          <Typography variant="body2">
            {getLabelForOrderMethodStatus(vinylStatus)}
          </Typography>
        </div>
      </div>
      {isMutatingOrder ? (
        <div className={classes.spinnerWrapper}>
          <CircularProgress />
        </div>
      ) : (
        <div className={classes.buttonContainer}>
          {canViewerCutVinyl && (
            <Button
              className={classes.spacerRight}
              color="primary"
              variant="contained"
              onClick={handleCutButtonClick}
            >
              Cut
            </Button>
          )}
          {canViewerCompleteVinyl && (
            <Button
              className={classes.spacerRight}
              color="primary"
              variant="contained"
              onClick={handleVinylCompletedButtonClick}
            >
              Vinyl Complete
            </Button>
          )}
          {canViewerPlaceOnHold && (
            <Button
              color="secondary"
              onClick={handleHoldButtonClick}
              variant="contained"
            >
              Hold
            </Button>
          )}
          {canViewerRemoveHold && (
            <Button
              color="secondary"
              onClick={handleRemoveHoldButtonClick}
              variant="contained"
            >
              Remove Hold
            </Button>
          )}
        </div>
      )}
      <PlaceOrderOnHoldModal
        isOpen={isPlacingOrderOnHold}
        onRequestClose={handlePlaceOrderOnHoldModalRequestClose}
        orderId={orderId}
      />
    </div>
  );
};

export default withSnackbar(VinylOrderStatus);
