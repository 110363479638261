import { gql, useQuery } from "@apollo/client";
import { makeStyles } from "@mui/styles";
import { useRouter } from "found";
import Button from "@mui/material/Button";
import React, { useCallback } from "react";
import Typography from "@mui/material/Typography";
import config from "../../config";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(3)
  }
}));

const VIEWER_QUERY = gql`
  query {
    viewer {
      id
      isAuthenticated
    }
  }
`;

const HomePageApp = () => {
  const classes = useStyles();
  const { router } = useRouter();

  const { data, loading, error } = useQuery(VIEWER_QUERY);

  const handleSignInButtonClick = useCallback(() => {
    window.location = `${config.getFlynetCoreLoginUrl()}?client_id=${encodeURIComponent(
      config.getFlynetCoreClientId()
    )}&return_to=${encodeURIComponent(window.location.href)}`;
  }, []);

  if (data?.viewer?.isAuthenticated) {
    router.push("/apps");
    return null;
  }

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography>Error!</Typography>;
  }

  return (
    <div className={classes.container}>
      <Button onClick={handleSignInButtonClick} color="primary">
        Sign in
      </Button>
    </div>
  );
};

export default HomePageApp;
