import { makeStyles, useTheme } from "@mui/styles";
import { useRouter } from "found";
import AppLauncherAppBar from "./components/AppLauncherAppBar";
import AppLauncherCard from "./components/AppLauncherCard";
import Grid from "@mui/material/Grid";
import HDDPressImage from "../../../assets/dtg.svg";
import HDDReadyImage from "../../../assets/dtg.svg";
import LocalInventoryImage from "../../../assets/pulledStock.svg";
import React from "react";
import ReceivingImage from "../../../assets/receiving.svg";
import StockStagedImage from "../../../assets/stockStaged.svg";
import useMediaQuery from "@mui/material/useMediaQuery";

const { REACT_APP_INVENTORY_MANAGEMENT_URL } = process.env;

const useStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(3)
  }
}));

const appButtons = [
  {
    location: "/mach6/apps/receiving",
    name: "Receiving",
    appImage: ReceivingImage
  },
  {
    location: "/mach6/apps/production/local-inventory",
    name: "Local Inventory",
    appImage: LocalInventoryImage
  },
  {
    location: `${REACT_APP_INVENTORY_MANAGEMENT_URL}/inventory`,
    name: "Inventory Management",
    appImage: LocalInventoryImage
  },
  {
    location: "/mach6/apps/counting-staging",
    name: "Counting / Staging",
    appImage: StockStagedImage
  },
  {
    location: "/mach6/apps/production/hdd-merging",
    name: "HDD Merging",
    appImage: HDDReadyImage
  },
  {
    location: "/mach6/apps/production/hdd-press",
    name: "HDD Pressing",
    appImage: HDDPressImage
  },
  {
    location: "/apps/customs/southbound",
    name: "Customs - Southbound",
    appImage: ReceivingImage
  },
  {
    location: "/apps/customs/northbound",
    name: "Customs - Northbound",
    appImage: ReceivingImage
  },
  {
    location: "/mach6/apps/bulk-box-location-setter",
    name: "Bulk Box Location Setter",
    appImage: ReceivingImage
  },
  {
    location: "/mach6/apps/bulk-film-location-setter",
    name: "Bulk Film Location Setter",
    appImage: ReceivingImage
  },
  {
    location: "/mach6/apps/check-return-status",
    name: "Audit Goods Status",
    appImage: ReceivingImage
  },
  {
    location: "/mach6/apps/film-check",
    name: "Audit Film Status",
    appImage: HDDReadyImage
  }
];

const AppLauncher = () => {
  const classes = useStyles();
  const theme = useTheme();
  const flexAlignment = useMediaQuery(theme.breakpoints.up("md"))
    ? "flex-start"
    : "center";
  const { router } = useRouter();

  const handleAppCardClick = location => {
    if (location.toLowerCase().startsWith("https://")) {
      window.open(location, "_blank");
    } else {
      router.push(location);
    }
  };

  return (
    <div>
      <AppLauncherAppBar />
      <div className={classes.container}>
        <Grid
          container
          spacing={1}
          justifyContent={flexAlignment}
          alignItems={flexAlignment}
        >
          {appButtons.map(({ location, name, appImage }) => (
            <Grid key={name} item md={4} lg={3} xl={2} sm={6}>
              <AppLauncherCard
                location={location}
                title={name}
                appImage={appImage}
                handleAppCardClick={() => handleAppCardClick(location)}
              />
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default AppLauncher;
