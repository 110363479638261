import { fragments as artOrderStatusFragments } from "../../application/apps/ArtApp/components/ArtOrderStatus/graph";
import { fragments as dtgOrderStatusFragments } from "../../application/apps/DTGApp/components/DTGOrderStatus/graph";
import { fragments as embroideryOrderStatusFragments } from "../../application/apps/EmbroideryApp/components/EmbroideryOrderStatus/graph";
import { gql } from "@apollo/client";
import { fragments as hddOrderStatusFragments } from "../../application/apps/HDDPressApp/components/HDDOrderStatus/graph";
import { fragments as notesWrapperFragments } from "../../components/NotesWrapper/graph";
import { fragments as screenPrintingOrderStatusFragments } from "../../application/apps/ScreenPrintingApp/components/ScreenPrintingOrderStatus/graph";
import { fragments as vinylOrderStatusFragments } from "../../application/apps/VinylApp/components/VinylOrderStatus/graph";

const markOrderAsOnHoldMutation = gql`
  mutation ($note: String!, $orderId: ID!) {
    markOrderAsOnHold(note: $note, orderId: $orderId) {
      errors {
        note
        orderId
      }
      order {
        ...ArtOrderStatus_order
        ...DTGOrderStatus_order
        ...EmbroideryOrderStatus_order
        ...NotesWrapper_order
        ...VinylOrderStatus_order
        ...ScreenPrintingOrderStatus_order
        ...HDDOrderStatus_order
      }
      succeeded
    }
  }
  ${artOrderStatusFragments.order}
  ${dtgOrderStatusFragments.order}
  ${embroideryOrderStatusFragments.order}
  ${hddOrderStatusFragments.order}
  ${notesWrapperFragments.order}
  ${screenPrintingOrderStatusFragments.order}
  ${vinylOrderStatusFragments.order}
`;

export default markOrderAsOnHoldMutation;
