import * as React from "react";
import { makeStyles } from "@mui/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

const useStyles = makeStyles(theme => ({
  tab: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

const TabNavigator = ({ selectedTab, onRequestTabChange, tabItems }) => {
  const classes = useStyles();

  const handleTabChange = (event, value) => onRequestTabChange(value);

  const tabs = tabItems.map((item, index) => {
    let value = item;
    let label = item;

    if (typeof item === "object") {
      value = item.value;
      label = item.label;
    }

    return (
      <Tab className={classes.tab} key={index} value={value} label={label} />
    );
  });

  return (
    <Tabs
      variant="scrollable"
      scrollButtons="auto"
      onChange={handleTabChange}
      value={selectedTab}
      textColor="secondary"
    >
      {tabs}
    </Tabs>
  );
};

export default TabNavigator;
