import * as React from "react";
import { markOrderAsOnHoldMutation } from "../../graph";
import { useMutation } from "@apollo/client";
import { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import PendingStatusView from "../../components/PendingStatusView";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import withSnackbar from "../../components/withSnackbar";

const PlaceOrderOnHoldModal = ({
  isOpen,
  onRequestClose,
  orderId,
  showErrorSnackbar
}) => {
  const [isPlacingOrderOnHold, setIsPlacingOrderOnHold] = useState(false);
  const [mutationErrors, setMutationErrors] = useState({
    note: [],
    orderId: []
  });
  const [notesInputValue, setNotesInputValue] = useState("");

  const { note: noteErrors, orderId: orderIdErrors } = mutationErrors;

  const [markOrderAsOnHold] = useMutation(markOrderAsOnHoldMutation);

  const handleNotesInputChange = event =>
    setNotesInputValue(event.target.value);

  const handleSubmitButtonClick = () => {
    setIsPlacingOrderOnHold(true);
    markOrderAsOnHold({
      variables: { orderId, note: `FlynetHold: ${notesInputValue}` }
    })
      .then(
        ({
          data: {
            markOrderAsOnHold: { errors, succeeded }
          }
        }) => {
          if (succeeded) {
            onRequestClose();
          } else {
            setMutationErrors(errors);
          }
        }
      )
      .catch(e => {
        showErrorSnackbar(e.message);
      })
      .finally(() => setIsPlacingOrderOnHold(false));
  };

  return (
    <Dialog
      aria-describedby="Place Order on Hold"
      aria-labelledby="PlaceOrderOnHoldModal"
      open={isOpen}
      onClose={onRequestClose}
      fullWidth={true}
    >
      <DialogTitle>Why are you locking this order?</DialogTitle>
      <DialogContent>
        {isPlacingOrderOnHold ? (
          <PendingStatusView status="Loading" />
        ) : (
          <React.Fragment>
            <TextField
              id="outlined-simple-start-adornment"
              variant="outlined"
              multiline={true}
              error={noteErrors.length > 0}
              helperText={noteErrors.length > 0 && noteErrors.join(", ")}
              label="Enter a production note here..."
              onChange={handleNotesInputChange}
              value={notesInputValue}
              fullWidth={true}
              rows={5}
            />
            {orderIdErrors.length > 0 && (
              <Typography color="error">{orderIdErrors.join(", ")}</Typography>
            )}
          </React.Fragment>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onRequestClose()} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmitButtonClick} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withSnackbar(PlaceOrderOnHoldModal);
