import { fragments as embroideryOrderStatusFragments } from "../../application/apps/EmbroideryApp/components/EmbroideryOrderStatus/graph";
import { gql } from "@apollo/client";

const markOrderAsTrimmingCompletedMutation = gql`
  mutation($orderId: ID!) {
    markOrderAsTrimmingCompleted(orderId: $orderId) {
      errors {
        orderId
      }
      order {
        ...EmbroideryOrderStatus_order
      }
      succeeded
    }
  }
  ${embroideryOrderStatusFragments.order}
`;

export default markOrderAsTrimmingCompletedMutation;
