import * as React from "react";
import { makeStyles } from "@mui/styles";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

const useStyles = makeStyles(() => ({
  card: {
    width: "auto"
  }
}));

const AppLauncherCard = ({
  title,
  appImage,
  handleAppCardClick
}) => {
  const classes = useStyles();

  return (
    <Card className={classes.card} onClick={handleAppCardClick}>
      <CardActionArea>
        <CardMedia component="img" src={appImage} />
        <CardContent>
          <Typography align="center" variant="h6">
            {title}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default AppLauncherCard;
