import { query } from "./graph";
import { useQuery } from "@apollo/client";
import { useRouter } from "found";
import AppBar from "../../../components/AppBar";
import ProductionAppWithSidebar from "../../../components/ProductionAppWithSidebar";
import React, { useState } from "react";

const ORDERS_PER_PAGE = 25;

const EmbroideryApp = () => {
  const [searchQuery, setSearchQuery] = useState(null);
  const { router } = useRouter();

  const { data, loading, error, fetchMore } = useQuery(query, {
    variables: {
      first: ORDERS_PER_PAGE,
      filters: {
        searchQuery: searchQuery
      },
      runQuery: !!(searchQuery && searchQuery.trim().length > 0)
    },
    fetchPolicy: "network-only"
  });

  const handleSearchQuerySubmit = searchQuery => {
    setSearchQuery(searchQuery);
  };

  const handleAppBarRequestBack = () => {
    router.push("/apps");
  };

  return (
    <ProductionAppWithSidebar
      data={data}
      destinationRoute="/apps/production/embroidery/orders"
      errorMessage={error ? error.message : null}
      fetchMore={fetchMore}
      loading={loading}
      ordersConnection={data?.ordersConnection}
    >
      <AppBar
        title="Embroidery"
        onSearch={handleSearchQuerySubmit}
        onRequestBack={handleAppBarRequestBack}
      />
    </ProductionAppWithSidebar>
  );
};

export default EmbroideryApp;
